import React from 'react';
import { UX2, components } from '@wsb/guac-widget-core';
import PropTypes from 'prop-types';

const { Link } = components;

const GalleryImage = (props) => {
  const Image = props.background ? <UX2.Component.Background { ...props.imageProps } /> : <UX2.Element.Image { ...props.imageProps } />;
  return props.externalLink ? <Link linkData={ props.externalLink }>{ Image }</Link> : Image;
};

GalleryImage.propTypes = {
  imageProps: PropTypes.object,
  background: PropTypes.bool,
  externalLink: PropTypes.object
};

export default GalleryImage;
